<template>
  <div>
    <div id="view-header__teleport__single"></div>
    <div v-if="!notationRoutes.includes($route.name)">
      <div class="view-header view-header--protocol-details" v-if="pageTitle">
        <div class="view-header__header" v-if="Object.keys(task).length>0">
          <div id="header-action"></div>
        </div>
        <div class="view-header__footer">
          <Btn
            v-if="$route.params.id"
            class="back-btn"
            round
            grow
            icon="arrow-left"
            :to="{
              name: 'essaiChildCalendar',
              params: {
                id: $route.params.id,
              },
            }"
          />
          <h1 class="page-title">
            <template v-if="!task?.designation">
              {{ task?.type?.designation }}
            </template>
            <template v-else>
              {{ task?.type?.designation }} - {{ task?.designation }}
            </template>
            <div class="page-subtitle">Essai :
              {{ essai?.numero ? `${essai?.numero} -` : '' }} {{ essai?.nom}}
            </div>
          </h1>
        </div>
      </div>
    </div>
  </div>

  <div class="view-body">
    <Section class="section section--no-padding">
      <TabsMaterial
        v-if="Object.keys(task).length && !notationRoutes.includes($route.name)"
        :tabs="tabs"
      />
      <router-view
        v-if="Object.keys(task).length"
        :task="task"
        :pageTitle="pageTitle"
        :isEditable="isEditable"
      ></router-view>
    </Section>
  </div>

  <NavigationDropdownEssai />
</template>

<script>
import Section from '@/components/layout/Section.vue'
import Btn from '@/components/base/Btn.vue'
import TabsMaterial from '@/components/layout/TabsMaterial.vue'
import NavigationDropdownEssai from '@/views/componentsViews/navigationDropdown/NavigationDropdownEssai.vue'

export default {
  name: 'ExperimentalTaskView',

  components: {
    NavigationDropdownEssai,
    Btn,
    Section,
    TabsMaterial,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      isEditable: true,
      task: {},
      essai: {},
      notationRoutes: [
        'essaiExperimentalTaskVariableStudiedSettings',
        'essaiExperimentalTaskVariableStudiedEntryLevel',
      ],
    }
  },

  computed: {
    tabs() {
      return [
        {
          urlName: 'essaiExperimentalTaskGeneralInformationChild',
          text: 'Informations générales',
        },
        ...(this.task?.type?.uid === 'PRELEVEMENT'
          ? [{ urlName: 'essaiExperimentalTaskOrganChild', text: 'Organe' }]
          : []), // Prélevement
        ...(this.task?.type?.uid === 'CONTAMINATION'
          ? [
            {
              urlName: 'essaiExperimentalTaskArtificialContaminationChild',
              text: 'Produit contaminant',
            },
          ]
          : []), // Contamination artificielle
        ...(this.task?.type?.uid === 'RECOLTE'
          ? [
            {
              urlName: 'essaiExperimentalTaskConditionsRecolteChild',
              text: 'Conditions de récolte',
            },
          ]
          : []), // Conditions de récolte
        ...(this.task?.type?.uid === 'DESTRUCTION'
          ? [
            {
              urlName: 'essaiExperimentalTaskDestructionVegetationChild',
              text: 'Destruction en végétation',
            },
            {
              urlName: 'essaiExperimentalTaskDestructionRecolteChild',
              text: 'Destruction en récolte',
            },
            {
              urlName: 'essaiExperimentalTaskDestructionProofChild',
              text: 'Preuves de destruction',
            },
          ]
          : []), // Destruction
        ...(this.task?.type?.uid === 'NOTATION'
          ? [
            { urlName: 'essaiExperimentalTaskParameterChild', text: 'Paramètres' },
            {
              urlName: 'essaiExperimentalTaskVariableStudiedListChild',
              text: 'Variables étudiées',
            },
            {
              urlName: 'essaiExperimentalTaskVariableCalculatedChild',
              text: 'Variables calculées',
            },
            { urlName: 'essaiExperimentalTaskNotationStateChild', text: 'Etat' },
          ]
          : []), // Notation
      ]
    },
  },

  mounted() {
    this.getEssai()
    this.getExperimentalTask()
  },

  methods: {
    setEditable(step) {
      if (step) {
        this.isEditable = ['ENPREPARATION'].includes(step)
      } else {
        this.isEditable = true
      }
    },
    getEssai() {
      this.fetchService.get(`essai/${this.$route.params.id}`).then(
        (response) => {
          this.essai = response.data
        },
      )
    },
    getExperimentalTask() {
      this.fetchService
        .get(
          `essai/${this.$route.params.id}/evenement/${this.$route.params.tid}`,
        )
        .then((reponse) => {
          this.task = reponse.data
          if (this.task.type.uid === 'NOTATION') {
            this.loadNotation()
          }
        })
    },
    loadNotation() {
      this.fetchService
        .get(
          `essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/notation`,
        )
        .then((reponseNotation) => {
          let notation = reponseNotation.data
          notation = notation.pop()
          this.fetchService
            .get(
              `essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/notation/${notation.id}/reservation?sort=id.DESC&limit=1`,
            )
            .then((reponseReservation) => {
              let reservation = reponseReservation.data
              reservation = reservation.pop()
              this.setEditable(reservation?.etat?.uid)
            })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.view-header__header {
  display: flex;
  gap: $gutter-half;
}
</style>
